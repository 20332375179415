<template>
    <div class="comp-active-box">
        <div v-if="item.dura_alias !== '无'" class="item-dura_alias  text-white px-1 rounded bg-4 ">
            {{ item.dura_alias }}
        </div>
        <div class="bg-img">
            <img v-if="item.level || item.lv" :src="require(`../../assets/images/Swiper/Lv${item.level || item.lv}.png`)"
                alt="">
        </div>
        <div class="show-img">
            <img :src="item.cover" alt="" srcset="">
        </div>
        <div class="price">
            <!-- ${{ item.bean }} -->
            <Cprice :price="item.bean" />
        </div>
        <div class="item-bottom" :class="`bg-${item.level || item.lv} show-${item.level || item.lv}`"></div>
    </div>
</template>

<script>
// 用在对战过程中摇动箱子的过程单个箱子
export default {
    props: {
        item: {
            type: Object,
            default: () => {
                return {}
            }
        }
    }
}
</script>

<style lang="scss">
.comp-active-box {
    background: #333;
    width: 100%;
    height: 100%;
    // background: pink;
    // border: 4px solid gold;
    position: relative;

    .item-dura_alias {
        position: absolute;
        right: 0.05rem;
        top: 0.05rem;
        z-index: 3;
    }

    .bg-img {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        width: 100%;
        height: 70%;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            height: 100%;
        }
    }

    .show-img {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        width: 100%;
        height: 55%;
        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            height: 100%;
        }
    }

    .price {
        color: #dea61f;
        position: absolute;
        left: 0.05rem;
        bottom: 0.2rem;
    }

    .item-bottom {
        width: 100%;
        height: 0.1rem;
        position: absolute;
        bottom: 0;
        left: 0;
    }
}
</style>