<template>
  <div @click="clickFun" class="Comp-OpenCase batter-bg" :class="{
      win: isEnd && !isInProgress && playerItem.winStatus !== 1,
      fail: isEnd && !isInProgress && playerItem.winStatus === 1,
      'three-box':gameDetail.user_num===3
    }">
    <div class="Top_KaiXiang">
      <div class="OpenCase-T">
        <div class="OpenCase-C">
          <div class="user" :class="{ 'user-bg': isEnd && playerItem.id }">
            <div class="Pic" :class="{ ShengLi: ShengFu == 'ShengLi' }">
              <img v-if="playerItem.avatar || (playerItem.user && playerItem.user.avatar)" :src="
                playerItem.avatar || (playerItem.user && playerItem.user.avatar)
              " alt="" />
              <img v-else src='../../../assets/images/battle/batter-user.png' alt="">
            </div>
            <p>
              {{ playerItem.name || (playerItem.user && playerItem.user.name)||'待加入'}}
            </p>
            <div v-if="isEnd && isInProgress" class="money">
              <img style="width: 0.12rem; margin-right: 0.04rem" src="../../../assets/images/about/user/huobi.png" alt="" />
              <countTo :startVal="startVal" :endVal="endVal" :duration="1000" :decimals="2"></countTo>
            </div>
            <div v-if="isEnd && !isInProgress" class="money money2">
              <span>{{ endVal }}</span>
            </div>
            <div v-if="isEnd && !isInProgress" class="money">
              <Cprice :price="playerItem.endMoney.toFixed(2)" :size="0.12" />
            </div>
          </div>
        </div>
        <div class="OpenCase-T-box" :class="ShengFu">
          <div v-if="!isEnd" class="BoxState">
            <div class="ready" v-if="!isEnd && playerItem.id">
              <img src="@/assets/images/battle/zhuan.png" alt="" />
              <div style="width:100%;color:#00FF19;font-size:0.1rem;">准备就绪</div>
            </div>
            <div v-else class="ready ready2">
              <div v-show="!playerItem.id" class="join-money-box">
                <Cprice :price="gameDetail.total_bean" :size="0.1" />
              </div>
              <div class="AddTo" v-show="!playerItem.id" @click="joinRoomOnClick">
                立即加入
              </div>
            </div>
          </div>
          <div class="LuckDraw" :class="{ LuckDrawHide: !isInProgress }" v-show="gameDetail.status !== 0">
            <div class="WinOrLose" :class="{ 'win-box': ShengFu === 'ShengLi' }" v-if="isEnd && !isInProgress">
              <div class="win-lose-img">

              </div>
              <div class="final-money-box">
                <div v-if="playerItem.winStatus === 1" class="text">
                  获得：
                  <Cprice size="0.1" :isWeight="false" :price="playerItem.endMoney.toFixed(2)" />
                </div>
                <div v-else class="text">
                  赢得：
                  <Cprice size="0.1" :isWeight="false" :price="playerItem.endMoney.toFixed(2)" />
                </div>
              </div>
              <!-- <img :style="`width: ${
                  2.4 / gameDetail?.game_arena_player?.length || 2
                }rem`" :src="ShengLiOrShiBai" alt="" /> -->
            </div>
            <div class="container" :key="ChangCiNum" v-else>
              <div class="Visible" :style="{
                  'margin-top': '-' + (this.Height * this.HeightNum + 'rem'),
                }">
                <div class="GameBox" v-for="(item, index) in Box_arr" :key="index">
                  <ASingleBox :item="item" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <!-- <div class="OpenCase-B" v-if="playerItem != undefined">
      <div class="reward" v-for="(item, index) in Prize_column" :key="index">
        <div class="pic">
          <img :src="item.cover || item.intact_cover" alt="" />
        </div>
        <div class="reward-info">
          <p>{{ item.name }}</p>
          <span>${{ item.bean }}</span>
        </div>
      </div>
    </div> -->
    <!-- <div v-if="isEnd && ChangCiNum > gameDetail?.box_num" class="text-img">
      <img :src="playerItem.winStatus !== 1 ? winImg : failImg" alt="" width="100%" />
    </div> -->
    <!-- {{ isInProgress }} -->
    <div class="OpenCase-B" v-show="isEnd||isInProgress">
      <!-- {{ playerItem }} -->
      <div class="reward" v-for="item in playerItem.selfList?.slice(0, ChangCiNum - 1).reverse()" :key="item.id + 'z'">
        <StaticSingleBox class="box-item" :item="item" />
      </div>
      <div v-show="isEnd && !isInProgress" style="width: 100%; height: 0rem; border: 1px solid;
border-image: linear-gradient(270deg, rgba(93, 93, 155, 0), rgba(93, 93, 155, 1), rgba(93, 93, 155, 0)) 1 1;margin:0.12rem 0;"></div>
      <div v-show="isEnd && !isInProgress" class="reward" v-for="item in playerItem.allList" :key="item.id + 'd'">
        <StaticSingleBox class="box-item" :item="item" />
      </div>
    </div>
  </div>
</template>

<script>
import { Arena_Join, Cash, PersonalInfo } from "@/network/api.js";
import countTo from "vue-count-to";
// import { mapActions, mapState } from "vuex";
import Goods from "@/components/PubOpen/Goods.vue";
import win from "@/assets/images/battle/batter4.png";
import fail from "@/assets/images/battle/batter5.png";
import winImg from "@/assets/images/battle/battleRoom/win-img.png";
import failImg from "@/assets/images/battle/battleRoom/fail-img.png";
import ActiveSingleBox from "@/components/singleBox/ActiveSingleBox.vue";
import StaticSingleBox from "@/components/singleBox/StaticSingleBox.vue";
import ASingleBox from "@/components/singleBox/ASingleBox.vue";
import { mapState, mapMutations } from "vuex";
export default {
  components: {
    Goods,
    countTo,
    ActiveSingleBox,
    StaticSingleBox,
    ASingleBox,
  },
  name: "OpenCase",
  props: {
    gameDetail: {
      type: Object,
      default() {
        return {};
      },
    },
    playerItem: {
      type: Object,
      default() {
        return {};
      },
    },
    ChangCiNum: {
      type: [Number, String],
      default: 1,
    },
    isEnd: {
      type: Boolean,
      default: true,
    },
    isInProgress: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      winImg,
      failImg,
      Height: 0.8, // 每个箱子高度
      HeightNum: 1, // 每个箱子高度*偏移次数
      Box_arr: [], //所有参与滚动的盒子
      // ShengFu: "ShengLi",
      // ShengLiOrShiBai: win,  //动画结束
      isStartTheDraw: false, //正在抽奖
      //临时参数
      endValNum: 0,
      showFinalBox: true,
      // allList: [],
      // finalFailList: [],
      startVal: 0,
      // endVal: 0,
      // isInProgress: false,
      Timer: null,
      isStartScroll: true,
    };
  },
  computed: {
    ShengLiOrShiBai() {
      let url = win;
      if (this.playerItem.winStatus === 1) {
        url = fail;
      }
      return url;
    },
    ShengFu() {
      let url = 'ShengLi';
      if (this.playerItem.winStatus === 1) {
        url = 'ShiBai';
      }
      return url;
    },
    endVal() {
      let endVal = 0;
      this.playerItem.selfList
        ?.slice(0, this.ChangCiNum - 1)
        .forEach((item) => {
          endVal += item.skins.bean * 1;
        });
      endVal = endVal.toFixed(2) * 1;
      return endVal;
    },
  },
  watch: {
    // isInProgress: {
    //   deep: true,
    //   immediate: true,
    //   handler(newVal) {
    //     if (newVal) {
    //       this.startScroll()
    //       // this.HeightNum = 1
    //       // this.StartTheDraw()
    //       // this.Timer = setInterval(() => {
    //       //   this.isStartTheDraw = false
    //       //   if (this.isEnd && this.ChangCiNum >= this.gameDetail.user_num) {
    //       //     // this.isInProgress = true
    //       //     clearInterval(this.Timer)
    //       //   } else {
    //       //     this.endVal += this.Box_arr[20].bean * 1
    //       //   }
    //       // }, 4000)
    //     }
    //   }
    // },
    ChangCiNum: {
      deep: true,
      immediate: true,
      handler(newVal) {
        this.HeightNum = 1;
        console.log('动了');
        this.startScroll();
      },
    },
    HeightNum: {
      deep: true,
      immediate: true,
      handler(newVal) {
        if (newVal == 20) {
          clearTimeout(this.clearTimeSet);
        }
      },
    },
  },
  methods: {
    ...mapMutations(["ChangeAudioStatus", "User"]),
    //个人信息
    GetPersonalInfo() {
      PersonalInfo().then((res) => {
        this.User(res.data.data);
      });
    },
    startScroll() {
      this.StartTheDraw();
      this.Timer = setTimeout(() => {
        this.isStartTheDraw = false;
        if (this.ChangCiNum > this.gameDetail.game_arena_box?.length) {
          clearTimeout(this.Timer);
        }
        this.$emit("addChangCiNum");
      }, 6500);
    },
    joinRoomOnClick() {
      const roomId = this.gameDetail.id;
      const seatId = this.playerItem.index;
      Arena_Join(roomId, seatId).then((res) => {
        this.GetPersonalInfo();
        if (res.data.code !== 200) {
          this.$message.error(res.data.message || "系统错误,请刷新后重试");
        }
      });
    },
    clickFun() { },
    //开始抽奖
    StartTheDraw() {
      if (!this.isEnd || this.isStartTheDraw) {
        return false;
      }
      console.log('走了', !this.isEnd, this.isStartTheDraw, this.ChangCiNum);
      this.isStartTheDraw = true;
      const choujiangBoxList =
        this.gameDetail.game_arena_box[this.ChangCiNum - 1]?.box.contains || [];
      let arr = [];
      for (let i = 0; i < 16; i++) {
        arr = [...arr, ...choujiangBoxList];
      }
      arr = arr.map((item) => {
        return {
          ...item,
          ...item.skins,
        };
      });

      function randSort1(arr) {
        for (let i = 0; i < arr.length; i++) {
          let rand = parseInt(Math.random() * arr.length);
          let temp = arr[rand];
          arr[rand] = arr[i];
          arr[i] = temp;
        }
        return arr;
      }

      this.Box_arr = randSort1(arr);
      //确定单次中将数据
      this.Prize_replacement(
        this.playerItem.game_award[this.ChangCiNum - 1]?.skins
      );
    },
    //确定奖品
    Prize_replacement(val) {
      this.Box_arr[20] = val;
      this.getsetTime();
    },
    //定时器(箱子动画)
    getsetTime() {
      if (this.clearTimeSet) {
        clearInterval(this.clearTimeSet);
      }
      this.clearTimeSet = setInterval(() => {
        this.HeightNum += 1;
      }, 30);
    },
  },
};
</script>

<style lang="scss" scoped>
// .batter-bg {
//   background: url(../../../assets/images/battle/batter3.png) no-repeat;
//   background-size: 100% 100%;
// }

// .win {
//   background: url(../../../assets/images/battle/batter6.png) no-repeat;
//   background-size: 100% 100%;
// }

// .fail {
//   background: url(../../../assets/images/battle/batter7.png) no-repeat;
//   background-size: 100% 100%;
//   // margin-left: 0.13rem;
// }

.Comp-OpenCase {
  // flex: 1;
  // width: 33%;
  overflow: hidden;
  box-sizing: border-box;
  // padding: 0 0.26rem;
  // min-height: 10.3rem;
  // width: 100%;
  // height: 2.02rem;
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-shrink: 0;
  margin: 0 0.06rem;
  // flex-shrink: 0;
  .Top_KaiXiang {
    // background: url(../../../assets/images/public/back_pub.jpg) no-repeat center;
    // background-size: cover;
    margin-bottom: 0.12rem;
  }
  font-size: 0.12rem;
  min-height: 2.02rem;
  .OpenCase-T {
    width: 100%;
    // height: 2rem;
    // padding: 0rem 0.05rem;
    // padding-top: 0.8rem;
    // padding-bottom: 0.3rem;
    box-sizing: border-box;
    overflow: hidden;
    padding-top: 0.1rem;
    background: #08081e;
    box-shadow: inset 3px 3px 3px 0px rgba(82, 82, 139, 0.4),
      inset -3px -3px 3px 0px rgba(93, 93, 155, 0.4);
    border-radius: 0px 0px 0px 0px;
    border: 1px solid rgba(93, 93, 155, 0.6);

    .OpenCase-T-box {
      width: 100%;
      height: 100%;
      position: relative;
      // background: pink;
      .BoxState {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        // padding: 0rem 0.5rem 0.1rem 0.5rem;
        // margin-bottom: 0.26rem;
        .ready {
          width: 100%;
          // height: rem;
          padding: 0.2rem 0;
          height: 0.8rem;
          box-sizing: border-box;
          // line-height: 0.5rem;
          // margin-top: 1.1rem;
          // margin-top: 0.51rem;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-wrap: wrap;
          // padding: 0.6rem 0.5rem 0.1rem 0.5rem;
          // margin-bottom: 0.26rem;
          img {
            width: 0.25rem;
            height: 0.25rem;
          }
        }
        .ready2 {
          // margin-top: 0.39rem;
        }

        // img {
        //   width: 0.6rem;
        //   height: 0.6rem;
        // }

        .wait {
          width: 100%;
          height: 0.5rem;
          line-height: 0.5rem;
          // margin-top: 1.1rem;
          text-align: center;
        }
        .join-money-box {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 0.12rem;
        }
        .AddTo {
          width: 0.8rem;
          height: 0.22rem;
          flex-shrink: 0;
          // background: #4854c9;
          background: url(../../../assets/images/mobile/m-batter9-new.png)
            no-repeat;
          background-size: 100% 100%;
          // border-radius: 0.04rem;
          // margin: 0.54rem auto 0;
          font-size: 0.1rem;
          display: flex;
          align-items: center;
          justify-content: center;

          text-align: center;
          // line-height: 0.2rem;
          margin-bottom: 0.02rem;
          // font-size: 0.14rem;
          // cursor: pointer;
        }
      }

      .LuckDraw {
        width: 100%;
        height: 1.08rem;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        // background: url(../../../assets/images/battle/batter1.png) no-repeat;
        // background-size: 100% 100%;
        // margin-bottom: 0.5rem;

        .WinOrLose {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          align-content: center;
          flex-wrap: wrap;
          background: url(../../../assets/images/battle/batter1-new2.png)
            no-repeat;
          background-size: 100% 100%;
          padding: 0.24rem 0.37rem;
          .win-lose-img {
            width: 0.8rem;
            height: 0.4rem;
            background: url(../../../assets/images/battle/batter1-new2-fail.png)
              no-repeat;
            background-size: 100% 100%;
          }
          .final-money-box {
            display: flex;
            font-size: 0.1rem;
            .text {
              display: flex;
              font-size: 0.1rem;
              white-space: nowrap;
            }
          }
        }
        .win-box {
          background: url(../../../assets/images/battle/batter1-new.png)
            no-repeat;
          background-size: 100% 100%;
          .win-lose-img {
            background: url(../../../assets/images/battle/batter1-new-win.png)
              no-repeat;
            background-size: 100% 100%;
          }
        }

        .container {
          width: 100%;
          height: 0.8rem;
          overflow: hidden;
          // padding-top: 0.8rem;
          // overflow: hidden;
          // mask-image: linear-gradient(0deg,
          //     rgba(25, 25, 25, 0) 4%,
          //     #000 60%,
          //     rgba(25, 25, 25, 0) 96%);

          .Visible {
            overflow: hidden;
            transition: 5.2s cubic-bezier(0, 0, 0.28, 1);
          }

          .GameBox {
            width: 100%;
            height: 0.8rem;
            // margin: 0.2rem auto;
            // display: flex;
            // justify-content: center;
            // align-items: center;

            // img {
            //   max-width: 80%;
            //   max-width: 80%;
            // }
          }
        }
      }

      .LuckDrawHide {
        background: unset;
        background-size: 100% 100%;
      }
    }
  }

  .OpenCase-C {
    width: 100%;
    // height: 4rem;
    // overflow: hidden;
    margin-bottom: 0.2rem;
    .user {
      width: 100%;
      flex-shrink: 0;
      // height: 3.6rem;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      // padding: 0.26rem 0;
      min-height: 0.9rem;
      font-size: 0.11rem;

      .Pic {
        // padding: 0 0.06rem;
        margin-bottom: 0.06rem;
        img {
          margin: 0 auto;
          width: 0.44rem;
          height: 0.44rem;
          border-radius: 50%;
          vertical-align: middle;
          overflow: hidden;
          margin-bottom: 0.02rem;
        }
        img:not([src]) {
          opacity: 0;
        }
      }

      p {
        // font-size: 0.26rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #6666cc;
        // margin-top: 0.12rem;
      }
    }

    .user-bg {
      // background: url(../../../assets/images/battle/batter2.png) no-repeat;
      // background-size: 100% 100%;
    }

    .money {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      // font-size: 0.34rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ecd105;
      margin-top: 0.04rem;
    }

    .money2 {
      // font-size: 0.19rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
      text-decoration: line-through;
      color: #999999;
    }
  }

  .OpenCase-B {
    width: 100%;
    padding: 0.05rem 0.03rem;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    max-height: 2.32rem;
    min-height: 0.9rem;
    overflow-y: auto;
    background: #0e0e2d;
    flex-grow: 1;
    align-content: flex-start;
    // margin: 0 0 0.3rem 0;

    .reward {
      // width: 31.6%;
      width: 48%;
      // height: 1.71rem;
      margin: 0 1%;
      margin-bottom: 0.05rem;
      border-radius: 0.02rem;
      flex-shrink: 0;

      // background: url(../../../assets/images/battle/battleRoom/good-bg.png) no-repeat;
      // background-size: 100% 100%;
      .box-item {
        // height: 0.58rem;
      }

      &:nth-child(3n) {
        margin-right: 0;
      }

      .pic {
        width: 80%;
        margin: 0.1rem auto 0;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 60%;

        img {
          width: 80%;
        }
      }

      .reward-info {
        width: 100%;
        box-sizing: border-box;
        padding: 0.08rem 0.15rem;
        // font-size: 0.19rem;
      }

      p {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #c7c5c5;
        box-sizing: border-box;
      }

      span {
        display: block;
        width: 100%;
        color: #db572e;

        img {
          width: 0.25rem;
          height: 0.25rem;
          vertical-align: middle;
          margin-right: 0.05rem;
        }
      }
    }
  }

  .text-img {
    text-align: center;
    margin: 0.2rem 0.1rem;
  }
}
.three-box {
  .WinOrLose {
    padding: 0 !important;
  }
  .OpenCase-B {
    padding: 0.05rem 0.15rem;
    .reward {
      width: 100%;
      margin: 0;
      margin-bottom: 0.05rem;
    }
  }
}
</style>
